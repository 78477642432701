import React from 'react'
import heroImage from 'Assets/images/heroImage.webp'
import heroImageSm from 'Assets/images/heroImage-small.webp'
import HeroAction from 'components/HeroAction'
import EmailPicker from 'components/EmailPicker'
import './Hero.css'

export default function Hero({ hero }) {
  return (
    <>
      <div className="relative overflow-hidden hero">
        <main className="sm:max-w-3xl lg:max-w-none xl:max-w-none 2xl:max-w-9xl mx-auto">
          <div className="hero-section lg:overflow-hidden">
            <div
              className="mx-auto py-10 sm:py-16 lg:py-12 lg:pb-16 max-w-7xl lg:px-8 bg-hero"
              style={{ background: 'url(' + heroImage + ')' }}
            >
              <div className="lg:grid lg:grid-cols-2 lg:gap-8">
                <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                  <div className="lg:py-16">
                    <HeroAction hero={hero} />

                    <div className="mt-10 sm:mt-12">
                      <EmailPicker email={hero.email} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-12 relative sm:max-w-3xl sm:mx-auto lg:hidden sm:flex">
              <div className="relative mx-auto w-full rounded-lg lg:max-w-md">
                <div className="relative block w-full bg-white rounded-lg overflow-hidden focus:outline-none ">
                  <img className="w-full" src={heroImageSm} alt="" />
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
