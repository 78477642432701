import React from 'react'
import Layout from 'container/Layout'
import Hero from 'container/Hero'

const App = () => {
  const hero = require('../data/hero.json')
  const common = require('../data/common.json')

  return (
    <Layout common={common}>
      <Hero hero={hero} />
    </Layout>
  )
}

export default App
