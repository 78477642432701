import React from 'react'

const HeroAction = ({ hero }) => {
  return (
    <>
      <h1>
        <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
          {hero.content.label}
        </span>
        <span className="mt-1 block text-4xl tracking-tight font-extrabold sm:text-5xl xl:text-6xl">
          {hero.content.heading.map(item => (
            <span className={'text-' + item.color} key={item.text}>
              {item.text + ' '}
            </span>
          ))}
        </span>
      </h1>
      <p className="mt-3 font-semibold sm:mt-5 sm:text-xl lg:text-lg">
        {hero.content.paragraph.map(item => (
          <span className={ item.classes } key={item.text}>
            {item.text + ' '}
        </span>
        ))}
      </p>
    </>
  )
}

export default HeroAction
